/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const { smoothAnchorate } = require('smooth-anchorate')
const SmoothScroll = require('smooth-scroll')

require('slick-carousel/slick/slick.css')
require('slick-carousel/slick/slick-theme.css')

exports.onRouteUpdate = () => {
  SmoothScroll('a[href*="#"]')
  smoothAnchorate()
}
